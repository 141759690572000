import { useEffect } from 'react'

export function BarcodeScanner({ targetInputId }) {

  useEffect(() => {
    let buffer = ''
    let lastInputTime = Date.now()

    const handleInput = (event) => {
      const currentTime = Date.now()
      const timeDiff = currentTime - lastInputTime
      
      if (timeDiff > 500) {
        buffer = ''
      }

      lastInputTime = currentTime

      if (event?.key === 'Enter') {
        if (buffer?.length > 0) {
          const targetInput = document.getElementById(targetInputId)
          if (targetInput) {
            targetInput.value = buffer
            targetInput.dispatchEvent(new Event('input', { bubbles: true }))
            targetInput.dispatchEvent(new Event('change', { bubbles: true }))
          }
          buffer = ''
        }
        return
      }

      if (event?.key?.length === 1) {
        buffer += event?.key
      }
    }

    window?.addEventListener('keydown', handleInput)

    return () => {
      window?.removeEventListener('keydown', handleInput)
    }
  }, [targetInputId])

  return (
    <></>
  )
}

