import React, { Suspense, createContext, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'src/scss/style.scss'
import { getRegisteredSchool, getRegisteredSchoolClass } from 'src/api/schoolRegisterService'
import CommonUtil from 'src/shared/CommonUtil'
import { MathJaxContext } from 'better-react-mathjax'
import Loader from 'src/components/Loader'
import { getDepartmentsAPI, getDesignationsAPI, getEmployeeTypesAPI, getEmployeesAPI } from 'src/api/employeeService'
import { getTag } from 'src/api/feeService'
import { getDaysAPI } from 'src/api/commonService'
import { getSessionsAPI } from 'src/api/sessionService'
import { Toaster } from 'react-hot-toast'
import data from 'src/data.json'
import { getModulesApi } from './api/moduleServices'
import { CModal, CModalBody } from '@coreui/react-pro'
import ForgotPw from './pages/forgotpw/ForgotPassword'
import { getSubClassWiseAPI } from './api/subjectService'
import CustomLogin from './pages/login/CustomLogin'
import CustomFW from './pages/forgotpw/CustomFw'
import { BarcodeScanner } from './components/BarcodeScanner'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('src/pages/login/Login'))
const Register = React.lazy(() => import('src/pages/register/Register'))
const RegisterSchool = React.lazy(() => import('src/pages/login/Login'))


export const appContext = createContext();
const App = () => {
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [leaveStructures, setLeaveStructures] = useState([]);
  const [salaryStructures, setSalaryStructures] = useState([]);
  const [tags, setTags] = useState([])
  const [days, setDays] = useState([])
  const [classOptions, setClassOptions] = useState([]);
  const schoolId = CommonUtil.getSelectedSchool()
  const [sessionsData, setSessionsData] = useState([])
  const [leaveCategories, setLeaveCategories] = useState([])
  const [sectionOptions, setSectionOptions] = useState([])
  const [modules, setModules] = useState([])
  const [appLoading, setAppLoading] = useState(false)
  const [schoolData, setSchoolData] = useState({})


  const getschoolData = async (id) => {
    try {
      let resp = await getRegisteredSchool({
        id: id ?? CommonUtil.getSelectedSchool()
      });
      if (resp && resp.code === 200) {
        let logo = resp?.data?.rows[0]['logo'];
        CommonUtil.setLocalStorage("allowInstallment", resp?.data?.rows[0]?.allowInstallment);
        CommonUtil.setLocalStorage("isPremium", resp?.data?.rows[0]?.isPremium);
        let schoolCode = resp?.data?.rows[0]['code'];
        let adress = resp?.data?.rows[0]['address'];
        let principalSign = resp?.data?.rows[0]['principalSign'];
        let school_id = resp?.data?.rows[0]['schoolId'];
        let affiliationNumber = resp?.data?.rows[0]['affiliationNumber'];
        const schoolAffiliation = resp?.data?.rows[0]['affiliation'];
        const schoolPhone = resp?.data?.rows[0]['phone'];
        const schoolEmail = resp?.data?.rows[0]['email'];
        const schoolSeal = resp?.data?.rows[0]['schoolSeal'];
        CommonUtil.setLocalStorage("selectedSchoolLogo", logo);
        CommonUtil.setLocalStorage("selectedSchoolAddress", adress);
        CommonUtil.setLocalStorage("affiliationNumber", affiliationNumber);
        CommonUtil.setLocalStorage("schoolCode", schoolCode);
        CommonUtil.setLocalStorage("isSchool", resp?.data?.rows[0]['isSchool']);
        const sign = await CommonUtil.getFile(principalSign, false, "principalSign");
        const seal = await CommonUtil.getFile(schoolSeal, false, "selectedSchoolSeal");
        const medicalOfficerSign = await CommonUtil.getFile(resp?.data?.rows[0]['medicalOfficerSign'], false, "medicalOfficerSign");
        const rectorSign = await CommonUtil.getFile(resp?.data?.rows[0]['rectorSign'], false, "rectorSign");
        CommonUtil.setLocalStorage("schoolAffiliation", schoolAffiliation);
        CommonUtil.setJsonLocalStorage("schoolContact", {
          phone: schoolPhone,
          email: schoolEmail
        });
        CommonUtil.setLocalStorage("school_id", school_id);
        const formattedSchool = resp?.data?.rows?.map(i => ({
          ...i,
          principalSign : sign,
          schoolSeal : seal,
          medicalOfficerSign,
          rectorSign
        }));
        setSchoolData(formattedSchool[0]);
        return formattedSchool[0];
      } else {
        console.log("error");
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getClassWiseSubjects = async () => {
    try {
      const resp = await getSubClassWiseAPI()
      if (resp?.code === 200) {
        return resp?.data
      } else {
        throw new Error(resp?.data?.message)
      }
    } catch (error) {

    }
  }
  const getClassList = async () => {
    if (schoolId && schoolId !== "-") {
      try {
        let resp = await getRegisteredSchoolClass(schoolId);
        if (resp && resp.code === 200) {
          const alloted = CommonUtil.getJsonLocalStorage("allotedClasses")
          if (CommonUtil.getLocalStorage("isAdmin") === "1" || CommonUtil.getLocalStorage("isSuperAdmin") === "1") {
            const subjects = await getClassWiseSubjects()
            const formatted = resp?.data?.map(i => ({
              ...i,
              "subjects": subjects?.find(j => j?.id === i?.id)?.Subjects?.map(k => ({
                "subjectId": k?.id
              }))
            }))
            // console.log(formatted, "classsssss")
            setClassOptions(formatted);
          } else {
            const classes = alloted?.map(i => i?.classId)
            const data = resp?.data.filter(i => classes?.includes(i?.id))?.map(j => ({ ...j, section: alloted?.find(k => k?.classId === j?.id)?.sections?.map(j => j.section), subjects: alloted?.find(k => k?.classId === j?.id)?.subjectId }))
            setClassOptions(data)
          }
          let clist = [{ label: CommonUtil.isCollege() ? "Select Program" : "Select Class", value: "" }];
          let sList = {}
          for (var i = 0; i < resp?.data.length; i++) {
            clist.push({ label: resp?.data[i]['className'], value: resp?.data[i]['id'] });
            if (alloted?.length === 0) {
              sList[resp?.data[i]['id']] = resp?.data[i]['section']
            } else {
              alloted.forEach(i => {
                sList[i?.classId] = i?.sections?.map(j => j.section)
              })
            }
          }
          CommonUtil.setJsonLocalStorage("selectedSchoolClass", clist);
          CommonUtil.setJsonLocalStorage("selectedSchoolClassSection", sList);
          setSectionOptions(sList)
        } else {
          console.log(resp?.data?.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setTimeout(() => {
        getClassList()
      }, 1000)
    }
  }
  const getDepartments = async () => {
    try {
      let resp = await getDepartmentsAPI();
      if (resp && resp.code === 200) {
        setDepartments(resp?.data);
      } else {
        throw new Error(resp?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getDesignations = async () => {
    try {
      let resp = await getDesignationsAPI();
      if (resp && resp.code === 200) {
        setDesignations(resp?.data);
      } else {
        throw new Error(resp?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getEmployeeTypes = async () => {
    try {
      let resp = await getEmployeeTypesAPI();
      if (resp && resp.code === 200) {
        setEmployeeTypes(resp?.data);
      } else {
        throw new Error(resp?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getEmployees = async (params) => {
    try {
      let resp = await getEmployeesAPI(params);
      if (resp && resp.code === 200) {
        setEmployeeData(resp?.data?.rows);
      } else {
        throw new Error(resp?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getEmployeesByParams = async (params, setFunc) => {
    try {
      let resp = await getEmployeesAPI(params);
      if (resp && resp.code === 200) {
        setFunc(resp?.data);
      } else {
        throw new Error(resp?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fetchTags = async (id) => {
    try {
      let resp = await getTag(id ? id : schoolId)
      if (resp && resp.code === 200) {
        setTags(resp?.data)
      } else {
        setTags([])
        throw new Error('error in fetching Tags ')
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getDays = async () => {
    try {
      const resp = await getDaysAPI();
      if (resp && resp?.code === 200) {
        setDays(resp?.data);
      } else {
        throw new Error(resp?.data?.message);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getSessions = async () => {
    try {
      const currentSessionType = CommonUtil.getLocalStorage("currentSessionType")
      let resp = await getSessionsAPI({
        "sessionType": +currentSessionType
      })
      if (resp?.code === 200) {
        setSessionsData(resp?.data?.rows)
      } else {
        throw new Error(resp?.data?.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getLeaveCategories = async () => {
    try {
      let resp = data?.leaveCategories
      setLeaveCategories(resp?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getModules = async () => {
    try {
      let resp = await getModulesApi()
      if (resp?.code === 200) {
        setModules(resp?.data)
      } else {
        setModules([])
        throw new Error(resp?.data?.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const runHolidayCron = async () => {
    try {
      // const resp = await runHolidayCRONAPI()
      const resp = {
        code: 200
      }
      if (resp?.code === 200) {
        console.log("CRON JOB RAN SUCCESSFULLY")
      } else {
        throw new Error(resp?.data?.message)
      }
    } catch (error) {
      console.log(error, "CRON JOB FAILED")
    }
  }



  useEffect(() => {
    const isLoggedIn = CommonUtil.getLocalStorage("access_token")
    if (isLoggedIn) {
      getDepartments()
      getDesignations()
      getEmployeeTypes()
      getEmployees()
      fetchTags()
      getDays()
      getSessions()
      getLeaveCategories()
      getModules()
      getschoolData()
      setTimeout(() => {
        getClassList()
      }, 1000)
    } else {
      if (!['/login', "/", "/forgot-password"].includes(window.location.pathname)) {
        window.location.href = "/";
      }
    }
  }, [])


  return (
    <appContext.Provider value={{ fetchTags, getClassList, departments, getDepartments, designations, getDesignations, employeeTypes, getEmployeeTypes, employeeData, getEmployees, tags, classOptions, days, getDays, leaveStructures, salaryStructures, getSessions, sessionsData, getLeaveCategories, leaveCategories, sectionOptions, getEmployeesByParams, modules, getModules, appLoading, setAppLoading, setClassOptions, setSectionOptions, getClassWiseSubjects, runHolidayCron, getschoolData, schoolData }}>
      <MathJaxContext>
        {
          appLoading && <CModal visible={true} alignment="center" size="sm">
            <CModalBody>
              <div className="d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            </CModalBody>
          </CModal>
        }
        <BrowserRouter>
          <Suspense fallback={
            <div style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <Loader />
            </div>
          }>
         
            <Routes>
              <Route exact path="/" name="Login Page" element={(window.location.origin === "https://school.myleadingcampus.com" || window.location.origin === "https://login.myleadingcampus.com" || window.location.origin === "https://emp.school.myleadingcampus.com") ? <Login /> : <CustomLogin />} />
              <Route exact path="/login" name="Login Page" element={(window.location.origin === "https://school.myleadingcampus.com" || window.location.origin === "https://login.myleadingcampus.com" || window.location.origin === "https://emp.school.myleadingcampus.com") ? <Login /> : <CustomLogin />} />
              <Route exact path="/forgot-password" name="Login Page" element={(window.location.origin === "https://school.myleadingcampus.com" || window.location.origin === "https://login.myleadingcampus.com" || window.location.origin === "https://emp.school.myleadingcampus.com") ? <ForgotPw /> : <CustomFW />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route exact path="/register-school" name="Register Page" element={<RegisterSchool />} />
              <Route exact path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </MathJaxContext>
      <Toaster />
      <BarcodeScanner />
    </appContext.Provider>
  )
}

export default App
