import moment from "moment";
import CommonUtil from "../shared/CommonUtil";
import apiService from "./apiService";


const session = CommonUtil.getSelectedSession()?.id
const schoolId = CommonUtil.getSelectedSchool()

export const getStudentswithFee = (params) => {
    params.schoolId = CommonUtil.getSelectedSchool()
    params.sessionId = session
    return apiService({
        url: `/student/student-fee`,
        method: 'GET',
        params: params
    });
}

export const getStudentFeeDetailsAPI = (studentId, module_id, isPromoted, date) => {
    let params = {}
    params.schoolId = CommonUtil.getSelectedSchool()
    params.sessionId = session
    params.studentId = studentId
    params.isCurrentSession = !isPromoted
    params.date = CommonUtil.formatDate(date || moment())
    return apiService({
        url: `/student/fee`,
        method: 'GET',
        params: params,
        module_id
    });
}

export function getFee(schoolId, classId, module_id, params) {
    let url
    if (session !== 0) url = `/fee/school/${schoolId}/class/${classId}/${session}`
    else url = `/fee/school/${schoolId}/class/${classId}`
    return apiService({
        url: url,
        method: 'GET',
        params: params,
        module_id
    });
}



export function addFee(payload, module_id) {
    if (session !== 0) payload.sessionId = session
    return apiService({
        url: "/fee",
        data: payload,
        method: 'post', module_id
    });
}

export function editFee(payload, feeId, module_id) {
    return apiService({
        url: `/fee/${feeId}`,
        data: payload,
        method: 'PATCH', module_id
    });
}

export function deleteFee(feeId, module_id) {
    return apiService({
        url: `/fee/${feeId}`,
        data: {},
        method: 'delete', module_id
    });
}

export function getTag(schoolId, module_id) {
    return apiService({
        url: `/tag/school/${schoolId}`,
        params: "",
        method: 'get', module_id
    });
}

export function getReceipt(paymentId, module_id) {
    return apiService({
        url: `/payment/${paymentId}.pdf/receipt`,
        params: {},
        method: 'get', module_id
    });
}

export function getDetailedReceipt(params, module_id) {
    params.sessionId = session
    return apiService({
        url: `/payment/reciept`,
        params: params,
        method: 'get', module_id
    });
}
export function getNewRecieptV2API(params) {
    params.sessionId = session
    params.schoolId = CommonUtil.getSelectedSchool()
    return apiService({
        url: `/payment/v2/fee-receipt`,
        params: params,
        method: 'get'
    });
}
export function getDetailedReceiptV2API(params, module_id) {
    params.sessionId = session
    params.schoolId = CommonUtil.getSelectedSchool()
    return apiService({
        url: `/payment/v2/detailed-receipt`,
        params: params,
        method: 'GET',
        module_id
    });
}

export function getReport(payld, module_id, content_type) {
    payld.sessionId = session
    return apiService({
        url: `/fee/report`,
        params: payld,
        method: 'get',
        module_id,
        content_type
    });
}
export function getNewReport(payld, module_id, content_type) {
    payld.sessionId = session
    return apiService({
        url: `/fee/v2/report`,
        params: payld,
        method: 'get',
        module_id,
        content_type
    });
}
export function getExcelReport(payld, module_id, content_type) {
    payld.sessionId = session
    return apiService({
        url: `/fee/excel-report`,
        params: payld,
        method: 'get',
        module_id,
        content_type
    });
}

export function getCollectFeeListAPI(params) {
    params.schoolId = CommonUtil.getSelectedSchool()
    params.sessionId = session
    return apiService({
        url: `/student/recieve-fee`,
        params,
        method: 'GET',
    });
}

export function collectFee(payload, module_id) {
    payload.sessionId = session
    payload.schoolId = CommonUtil.getSelectedSchool()
    return apiService({
        url: "/payment",
        data: payload,
        method: 'post', module_id
    });
}


export function updateConcessionAPI(amount, id, module_id) {
    return apiService({
        url: "/concession/" + id,
        data: {
            amount: amount
        },
        method: 'PATCH',
        module_id
    });
}
export function deleteConcessionAPI(id, module_id) {
    return apiService({
        url: "/concession/" + id,
        method: 'DELETE',
        module_id
    });
}

export function sendRemainder(studentId, type, module_id) {
    return apiService({
        url: `/student/${studentId}/fees-reminder?type=${type}`,
        data: {},
        method: 'POST',
        module_id
    });
}

export function failedPaymentsAPI(params, module_id) {
    params.schoolId = CommonUtil.getSelectedSchool()
    params.sessionId = session
    return apiService({
        url: `/order/failed`,
        params,
        method: 'GET',
        module_id
    });
}

export function reversePaymentAPI(id, module_id) {
    return apiService({
        url: "/payment/" + id,
        data: {
            "isReversed": true
        },
        method: 'PATCH',
        module_id
    });
}

export function reversePaymentV2API(id) {
    return apiService({
        url: "/payment/v2/" + id,
        data: {
            "isReversed": true
        },
        method: 'PATCH',
    });
}
export function reverseCollegePayment(id) {
    return apiService({
        url: "/college/payment/" + id,
        data: {
            "isReversed": true
        },
        method: 'PATCH',
    });
}

export function deletePaymentAPI(transId) {
    return apiService({
        url: "/payment/" + transId,
        method: 'DELETE',
    });
}
export function addOtherConcessionAPI(payload = {}) {
    payload.sessionId = session
    payload.schoolId = CommonUtil.getSelectedSchool()
    return apiService({
        url: "/concession-for-service",
        method: 'POST',
        data: payload
    });
}
export function updateOtherConcessionAPI(payload = {}, id) {
    return apiService({
        url: "/concession-for-service/" + id,
        method: 'PATCH',
        data: payload
    });
}
export function deleteOtherConcessionAPI(id) {
    return apiService({
        url: "/concession-for-service/" + id,
        method: 'DELETE',
    });
}
export function getOtherConcessionAPI(params = {}) {
    params.sessionId = session
    params.schoolId = CommonUtil.getSelectedSchool()
    return apiService({
        url: "/concession-for-service",
        method: 'GET',
        params
    });
}

export function addCollegeFeeAPI(payload, module_id) {
    if (session !== 0) payload.sessionId = session
    payload.schoolId = CommonUtil.getSelectedSchool()
    payload.sessionId = session
    return apiService({
        url: "/college/fee",
        data: payload,
        method: 'post',
        module_id
    });
}

export const getCollegeFeeAPI = (params) => {
    params.schoolId = CommonUtil.getSelectedSchool()
    params.sessionId = session
    return apiService({
        url: `/college/fee`,
        method: 'GET',
        params
    });
}

export function deleteFeeStructure(id) {
    return apiService({
        url: `/college/fee/${id}`,
        method: 'DELETE',
    });
}

export function editFeeStructure(editPayload, editId) {
    return apiService({
        url: `/college/fee/${editId}`,
        method: 'PATCH',
        data: editPayload
    });
}
export function addCollegeConcessionAPI(payload) {
    return apiService({
        url: "/college/concession",
        method: 'POST',
        data: payload
    });
}
export function getCollegeConcessionAPI(params) {
    params.sessionId = session
    return apiService({
        url: `/college/concession`,
        method: 'GET',
        params
    });
}
export function updateCollegeConcessionAPI(amount, id) {
    return apiService({
        url: "/college/concession/" + id,
        method: 'PATCH',
        data: amount
    });
}
export function deleteCollegeConcessionAPI(id) {
    return apiService({
        url: "/college/concession/" + id,
        method: 'DELETE'
    });
}
export function getCollegeFeeReceiveAPI(params) {
    params.sessionId = session
    params.schoolId = schoolId
    params.date = CommonUtil.formatDate(params.date || moment());
    
    return apiService({
        url: `/college/student`,
        method: 'GET',
        params
    });
}

export function getCollegeFeeReceiveAPI__NEW(params) {
    params.sessionId = session
    params.schoolId = schoolId
    params.date = CommonUtil.formatDate(params.date || moment())
    return apiService({
        url: `/college/student/fee-detail`,
        method: 'GET',
        params
    });
}

export const setCollegePaymentAPI = (payload) => {
    payload.schoolId = CommonUtil.getSelectedSchool()
    payload.sessionId = session
    // payload.dateOfPayment = CommonUtil.formatDate(payload.dateOfPayment || moment())
    payload.dateOfPayment = CommonUtil.formatDate(payload.date || moment());

    console.log(payload);
    

    return apiService({
        url: `/college/payment`,
        method: 'POST',
        data: payload
    });
}

export function getFeeReciptAPI(params) {
    return apiService({
        url: `/college/payment/fee-recipet`,
        method: 'GET',
        params,
        content_type: 'text/html'
    });
}

export const setCollegeInstallmentPaymentAPI = (payload={}) => {
    payload.schoolId = CommonUtil.getSelectedSchool()
    // payload.collegePaymentId = session
    payload.sessionId = session
    payload.dateOfPayment = CommonUtil.formatDate(payload.date || moment())

    return apiService({
        url: `/college/payment`,
        method: 'POST',
        data: payload
    });
}

export function getCollegeReport(params={}) {
    params.sessionId = session
    params.schoolId = schoolId
    return apiService({
        url: `/college/fee/report`,
        params: params,
        method: 'GET'
    });
}

// export function sendCollegeRemainder(studentId,queryObj) {
//     params.schoolId = schoolId
//     params.sessionId = session
//     params.studentId = studentId
//     params.pageNumber = queryObj.pageNumber
//     params.pageSize = queryObj.pageSize
//     return apiService({
//         url: `/college/student/fee`,
//         method: 'POST',
//         params
//     });
// }

export const getCollegeStudentswithFee = (params, queryObj) => {
    params.schoolId = schoolId
    params.sessionId = session
    // params.studentId = params.id
    params.pageNumber = queryObj.pageNumber
    params.pageSize = queryObj.pageSize
    return apiService({
        url: `/college/student/fee`,
        method: 'GET',
        params: params
    });
}