import CommonUtil from "../shared/CommonUtil";
import apiService from "./apiService";

export function getRegisteredSchool(pData,module_id) {
    return apiService({
        url: "/school",
        method: 'get',
        params: pData,
        Authorization: 'Bearer '+CommonUtil.getLocalStorage('access_token'),module_id
    });
}


export function registerSchool(payload,module_id) {
  return apiService({
        url: "/school",
        data: payload,
        method: 'post',
        Authorization: 'Bearer '+CommonUtil.getLocalStorage('access_token'),module_id
    });
}

export function updateRegisterSchool(payload, sId,module_id) {
  return apiService({
        url: "/school/"+sId,
        data: payload,
        method: 'PATCH',
    });
}



export function getRegisteredSchoolClass(sid,module_id) {
    return apiService({
        url: "/setup/class/"+sid,
        method: 'get',
        Authorization: 'Bearer '+CommonUtil.getLocalStorage('access_token'),
        module_id,
        passaccess:true
        
    });
}

export function addRegisteredSchoolClass(payload,module_id) {
    return apiService({
        url: "/setup/class",
        data: payload,
        method: 'Post',
        Authorization: 'Bearer '+CommonUtil.getLocalStorage('access_token'),module_id
    });
}
export function editRegisteredSchoolClass(payload, classId,module_id) {
    return apiService({
        url: `/setup/class/${classId}`,
        data: payload,
        method: 'PATCH',
        Authorization: 'Bearer '+CommonUtil.getLocalStorage('access_token'),module_id
    });
}
export function deleteRegisteredSchoolClass(classId,module_id) {
    return apiService({
        url: `/setup/class/${classId}`,
        data: {},
        method: 'DELETE',
        Authorization: 'Bearer '+CommonUtil.getLocalStorage('access_token'),module_id
    });
}
export function getHeadBranchesAPI() {
    return apiService({
        url: `/school/head-branch`,
        method: 'GET',
    });
}
export function getSigsAPI() {
    return apiService({
        url: `/class-teacher-sign`,
        method: 'GET',
        params: {
            schoolId : CommonUtil.getSelectedSchool()
        }
    });
}
export function postSigsAPI(data) {
    return apiService({
        url: `/class-teacher-sign`,
        method: 'POST',
        data
    });
}
export function updateSigsAPI(id, fileName) {
    return apiService({
        url: `/class-teacher-sign/${id}`,
        method: 'PATCH',
        data: {
            classTeacherSign :  fileName
        }
    });
}


